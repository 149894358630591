<template>
  <div id="news-wrapper">
    <div class="banner">
      <div class="bg-filter"></div>
      <div class="text-wrapper">
        <div>{{ navList[currentIndex].name }}</div>
        <div>{{ navList[currentIndex].englishName }}</div>
      </div>
      <SubNav :navList="navList" @giveIndex="takeIndex" />
    </div>
    <router-view></router-view>
    <ToTop />
  </div>
</template>

<script>
import SubNav from "@/components/SubNav";
import ToTop from "@/components/ToTop.vue";

export default {
  name: "News",
  components: {
    SubNav,
    ToTop
  },
  data() {
    return {
      index: 0,
      navList: [
        {
          name: "新闻资讯",
          englishName: "NEWS INFORMATION",
          path: "/news/information",
          component: "InformationIndex"
        }
        // {
        //   name: "项目故事",
        //   englishName: "PROJECT STORY",
        //   path: "/news/story",
        //   component: "Story"
        // }
      ]
    };
  },
  computed: {
    currentIndex() {
      let { path } = this.$route;
      let currentPath = path.split("/")[2];
      if (currentPath == "information") {
        return 0;
      } else {
        return 1;
      }
    }
  },
  methods: {
    takeIndex(index) {
      this.index = index;
    }
  }
};
</script>

<style lang="scss" scoped>
#news-wrapper {
  color: #1d1d1f;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner {
  background-image: url(../../assets/images/two-banner.jpeg);
  background-position: 50% 35%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 80vw;
  min-width: 1240px;
  max-width: 100%;
  height: 23vw;
  min-height: 340px;
  max-height: 430px;
  background-size: cover;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
  // scroll-behavior: smooth;
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(37, 163, 246, 0.267);
  }
  // 文字
  .text-wrapper {
    z-index: 10;
    div:first-child {
      font-size: 40px;
      margin-bottom: 30px;
    }
    div:last-child {
      font-size: 20px;
    }
  }
}
</style>
